var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g_page_box"},[_c('div',{staticClass:"g_main_content"},[_c('g-loading',{attrs:{"loading":_vm.loading}}),_c('div',{staticClass:"steps-nav"},[_c('van-steps',{attrs:{"active":_vm.active}},[_c('van-step',[_vm._v("回答问题")]),_c('van-step',[_vm._v("推荐方案")]),_c('van-step',[_vm._v("预约")])],1)],1),_c('div',{staticClass:"risk-form-box"},[_vm._m(0),_c('div',{staticClass:"risk-form-box-bodyer"},_vm._l((_vm.list),function(item,index){return _c('div',{key:'item_' + index,class:[
            item.sel
              ? 'risk-form-item risk-form-item-actived'
              : 'risk-form-item ',
          ],on:{"click":function($event){return _vm.onTap(index, _vm.list)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('div',{staticClass:"risk-form-box"},[_vm._m(1),_c('div',{staticClass:"risk-form-box-bodyer"},_vm._l((_vm.list1),function(item,index){return _c('div',{key:'item_' + index,class:[
            item.sel
              ? 'risk-form-item risk-form-item-actived'
              : 'risk-form-item ',
          ],on:{"click":function($event){return _vm.onTap(index, _vm.list1)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('div',{staticClass:"risk-form-box"},[_vm._m(2),_c('div',{staticClass:"risk-form-box-bodyer"},_vm._l((_vm.list2),function(item,index){return _c('div',{key:'item_' + index,class:[
            item.sel
              ? 'risk-form-item risk-form-item-actived'
              : 'risk-form-item ',
          ],on:{"click":function($event){return _vm.onTap(index, _vm.list2)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('div',{staticClass:"risk-form-box-btn",on:{"click":_vm.onTapRisk}},[_vm._v("测算风险")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"risk-form-box-header"},[_c('h3',[_vm._v("生活习惯")]),_c('p',[_vm._v("不良生活习惯是患病风险因素")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"risk-form-box-header"},[_c('h3',[_vm._v("家族史")]),_c('p',[_vm._v("亲戚否有患有以下疾病")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"risk-form-box-header"},[_c('h3',[_vm._v("既往史")]),_c('p',[_vm._v("不良生活习惯是患病风险因素")])])
}]

export { render, staticRenderFns }