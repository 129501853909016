<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="steps-nav">
        <van-steps :active="active">
          <van-step>回答问题</van-step>
          <van-step>推荐方案</van-step>
          <van-step>预约</van-step>
        </van-steps>
      </div>

      <div class="risk-form-box">
        <div class="risk-form-box-header">
          <h3>生活习惯</h3>
          <p>不良生活习惯是患病风险因素</p>
        </div>
        <div class="risk-form-box-bodyer">
          <div
            :class="[
              item.sel
                ? 'risk-form-item risk-form-item-actived'
                : 'risk-form-item ',
            ]"
            v-for="(item, index) of list"
            :key="'item_' + index"
            @click="onTap(index, list)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="risk-form-box">
        <div class="risk-form-box-header">
          <h3>家族史</h3>
          <p>亲戚否有患有以下疾病</p>
        </div>
        <div class="risk-form-box-bodyer">
          <div
            :class="[
              item.sel
                ? 'risk-form-item risk-form-item-actived'
                : 'risk-form-item ',
            ]"
            v-for="(item, index) of list1"
            :key="'item_' + index"
            @click="onTap(index, list1)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="risk-form-box">
        <div class="risk-form-box-header">
          <h3>既往史</h3>
          <p>不良生活习惯是患病风险因素</p>
        </div>
        <div class="risk-form-box-bodyer">
          <div
            :class="[
              item.sel
                ? 'risk-form-item risk-form-item-actived'
                : 'risk-form-item ',
            ]"
            v-for="(item, index) of list2"
            :key="'item_' + index"
            @click="onTap(index, list2)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="risk-form-box-btn" @click="onTapRisk">测算风险</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      list: [
        { name: "吸烟", sel: false },
        { name: "喝酒", sel: false },
        { name: "熬夜", sel: false },
        { name: "腌制食品", sel: false },
        { name: "甜食", sel: false },
        { name: "熬夜", sel: false },
        { name: "碳酸饮料", sel: false },
      ],
      list1: [
        { name: "高血压", sel: false },
        { name: "糖尿病", sel: false },
        { name: "冠心病", sel: false },
        { name: "胃癌", sel: false },
        { name: "肠癌", sel: false },
        { name: "严重精神障碍", sel: false },
      ],
      list2: [
        { name: "胃炎", sel: false },
        { name: "肾结石", sel: false },
      ],
      loading: false,
      active: 0,
      sel_list: [],
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {},
  methods: {
    onTap(index, list) {
      list[index].sel = !list[index].sel;
    },
    onTapRisk() {
      this.$router.push({ path: "/health-check-result" });
    },
  },
};
</script>

<style scoped>
.g_main_content {
  background: #f2f7f8;
}
.steps-nav {
  background: #fff;
  padding: 20px 16px;
  box-shadow: 0 2px 6px #ebedf0;
}

.risk-form-box-btn {
  height: 44px;
  display: block;
  text-align: center;
  color: #fff;
  background: #1890ff;
  font-size: 16px;
  line-height: 44px;
  border-radius: 22px;
  font-weight: 600;
  margin: 20px 14px;
}

.risk-form-box {
  margin-top: 20px;
  background: #fff;
  padding: 16px;
}

.risk-form-box-header {
  & h3 {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.6;
  }
  & p {
    color: #5e5e5e;
    font-size: 12px;
  }
  margin-bottom: 14px;
}

.risk-form-box-bodyer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
  flex-wrap: wrap;
}

.risk-form-item {
  background: #f7f8fa;
  height: 32px;
  color: #323233;
  margin-right: 10px;
  padding: 0px 14px;
  text-align: center;
  line-height: 32px;
  margin-bottom: 10px;
}
.risk-form-item-actived {
  background: #1890ff;
  color: #fff;
}
</style>
